import React from "react";
import { TextField, MenuItem, FormControl as MuiFormControl } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  min-width: 148px;
`;
const SelectWrapper = ({ name, options, onChange, ...otherProps }) => {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    const { value } = event.target;
    setFieldValue(name, value); // Formik updates the value here
    if (onChange) {
      onChange(event); // Call the onChange prop with the event if provided
    }
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    onChange: handleChange, // Use our handleChange to update Formik
    fullWidth: true,
    value: values[name], // Ensure the value comes from Formik's values
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <FormControl fullWidth my={2}>
      <TextField {...configSelect}>
        {options.map(({ key, value }, index) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default SelectWrapper;
