import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Dropzone from "../../components/FileDropZone.js";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import moment from "moment/moment.js";
import { format } from "date-fns";
import SelectWrapper from "../../components/SelectBoxAutoComplete";
import apiUtils from "../../utils/apiUtils";
import ErrorPopup from "../../components/ErrorPopup";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const CardContent = styled(MuiCardContent)(spacing);

const AddInvoices = () => {
  const [tableData, setTableData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]); // Add this line for error messages
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);

  const [filename, setFilename] = useState("");
  const [containsInvoiceNumber, setContainsInvoiceNumber] = useState(true); // Assuming it starts as true, update it accordingly based on your initial data

  // Fetch data from the API
  const supplierData = apiUtils.useApiData("/api/v1/suppliers");
  const currencyData = apiUtils.useApiData("/api/v1/currencies");
  const productData = apiUtils.useApiData("/api/v1/productTypes");
  const customerData = apiUtils.useApiData("/api/v1/entities");
  const countryData = apiUtils.useApiData("/api/v1/countries");

  // Function to open/close the error popup
  const toggleErrorPopup = () => {
    setIsErrorPopupOpen((prevOpen) => !prevOpen);
  };

  // Function to close the error popup and reset the errorMessages state
  const closeErrorPopup = () => {
    setIsErrorPopupOpen(false);
    setErrorMessages([]); // Reset error messages
  };

  const handleFileObject = (file, rowIndex) => {
    tableData[rowIndex].fileObject = file;
    const invoiceNumber = tableData[rowIndex].InvoiceNumber;
    setFilename(file.name);
    setContainsInvoiceNumber(file.name.includes(invoiceNumber));
  };

  const handleSupplierUpdate = (supplierId, rowIndex) => {
    // alert(rowIndex);
    tableData[rowIndex].SupplierId = supplierId;

    // console.table(tableData);
  };

  const handleCountryUpdate = (countryId, rowIndex) => {
    let newTableData = [...tableData];
    newTableData[rowIndex].CountryCode = countryId;
    setTableData(newTableData);
  };

  const handleProductUpdate = (productTypeId, rowIndex) => {
    // alert(rowIndex);
    tableData[rowIndex].ProductType = productTypeId;

    // console.table(tableData);
  };

  const columns = [
    { field: "__rowNum__", headerName: "ID", width: 50, hide: true },
    { field: "CustomerId", headerName: "Customer Id", flex: 1, hide: false },
    {
      field: "InvoiceNumber",
      headerName: "Invoice number",
      flex: 1,
      editable: true,
    },
    {
      field: "InvoiceDate",
      headerName: "Invoice date",
      flex: 1,
      editable: true,
      valueFormatter: (params) => format(Date.parse(params?.value), "dd-MM-yyyy"),
    },
    // { field: "SupplierId", headerName: "SupplierId", flex: 1 },
    {
      field: "SupplierId",
      headerName: "Supplier",
      flex: 2,
      renderCell: (params) => {
        const currentSupplierCode = tableData[params.api.getRowIndex(params.row.__rowNum__)].SupplierId;
        const currentSupplierCodeId = supplierData.find((s) => s.supplierCode === currentSupplierCode)?.supplierId;
        // const options = supplierData
        //   .filter((item) => item.supplierId === currentSupplierCode)
        //   .map((item) => ({
        //     key: item.supplierId,
        //     value: item.supplierName,
        //   }));
        // {
        //   console.table(supplierData);
        // }
        return (
          <SelectWrapper
            name="supplier"
            rowIndex={params.api.getRowIndex(params.row.__rowNum__)}
            selectedKey={currentSupplierCodeId}
            // label="Supplier"
            options={JSON.parse(
              JSON.stringify(JSON.parse(JSON.stringify(supplierData).split('"supplierId":').join('"key":')))
                .split('"supplierName":')
                .join('"value":')
            )}
            handleChange={handleSupplierUpdate}
          />
        );
      },
    },
    {
      field: "CountryCode",
      headerName: "Country (MSR)",
      flex: 2,
      renderCell: (params) => {
        return (
          <SelectWrapper
            name="companyCountry"
            rowIndex={params.api.getRowIndex(params.row.__rowNum__)}
            selectedKey={params.row.CountryCode}
            options={JSON.parse(
              JSON.stringify(JSON.parse(JSON.stringify(countryData).split('"countryId":').join('"key":')))
                .split('"countryName":')
                .join('"value":')
            )}
            handleChange={handleCountryUpdate}
          />
        );
      },
    },
    {
      field: "ProductType",
      headerName: "Product Type",
      flex: 2,
      renderCell: (params) => {
        const currentCountryCode = tableData[params.api.getRowIndex(params.row.__rowNum__)].CountryCode;

        const options = productData
          .filter((item) => item.countryCode === currentCountryCode)
          .map((item) => ({
            key: item.productTypeId,
            value: item.productLongDescription === "" ? item.productShortDescription : item.productLongDescription,
          }));
        // {
        //   console.table(options);
        // }
        return (
          <SelectWrapper
            name="productType"
            rowIndex={params.api.getRowIndex(params.row.__rowNum__)}
            selectedKey={params.row.ProductType}
            options={options}
            handleChange={handleProductUpdate}
            // optional1={currentCountryCode}
          />
        );
      },
    },

    {
      field: "Currency",
      headerName: "Currency",
      flex: 0.5,
      valueGetter: (params) =>
        currencyData.length > 0
          ? currencyData.find((cur) => cur.currencyId === countryData.find((cou) => cou.countryId === params.row.CountryCode).currency)
              .isoCode
          : "",
    },
    {
      field: "GrossAmount",
      headerName: "Gross amount",
      type: "number",
      valueFormatter: ({ value }) => value.toFixed(2),
      flex: 1,
    },
    {
      field: "VATAmountLocalCurrency",
      headerName: "VAT amount local currency",
      type: "number",
      valueFormatter: ({ value }) => value.toFixed(2),
      flex: 1,
    },
    // {
    //   field: "VATAmountEur",
    //   headerName: "Vat amount Eur",
    //   type: "number",
    //   valueFormatter: ({ value }) => value.toFixed(2),
    //   flex: 1,
    //   editable: true,
    //   hide: true,
    // },
    // { field: "fileId", headerName: "FileId", flex: 1 },
    {
      field: "copyPDF",
      headerName: "CopyPDF",
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          <Dropzone
            handleFile={handleFileObject}
            rowIndex={params.api.getRowIndex(params.row.__rowNum__)}
            fileObject={params.row.fileObject}
            selectedInvoiceNumber={params.row.InvoiceNumber}
            filename={filename} // Pass the filename to the Dropzone component
            containsInvoiceNumber={containsInvoiceNumber} // Pass the invoiceNumber status to the Dropzone component
          />
        </Box>
      ),
    },
  ];

  const onChangeFileDrop1 = (event) => {
    // alert("wefwr");
  };

  const getEntityId = (customerNumber) => {
    let entityId = null;
    if (customerNumber) {
      entityId = customerData.find((customer) => customer.entityNumber === customerNumber).entityId;
    }
    return entityId;
  };

  // Helper function to create FormData
  const createFormData = (file, id) => {
    const data = new FormData();
    data.append("file", file, id);
    return data;
  };

  // Helper function to format date
  const formatDate = (date) => format(Date.parse(date), "yyyy-MM-dd");

  async function uploadInvoiceToDB(row) {
    let newInvoice = {};
    let supplierId = supplierData.find((s) => s.supplierCode === row.SupplierId)?.supplierId;
    try {
      newInvoice = {
        entityId: getEntityId(row.CustomerId),
        invoiceNumber: row.InvoiceNumber.toString(),
        invoiceDate: formatDate(row.InvoiceDate),
        supplierId: supplierId, //row.SupplierId,
        countryCode: row.CountryCode,
        claimProductTypeId: row.ProductType,
        currencyId: currencyData.find(
          (cur) => cur.currencyId === countryData.find((cou) => cou.countryId === row.CountryCode).currency
        ).currencyId,
        invoiceGrossAmount: row.GrossAmount.toFixed(2),
        invoiceVATAmountLocalCurrency: row.VATAmountLocalCurrency.toFixed(2),
        invoiceVATAmountEUR: "0.00",
        dataEntryDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
        dataEntryOperator: "System",
        fileId: -1,
        error: null, // Initialize the error property to null
      };
      const response = await axios.post("/api/v1/customerInvoices", newInvoice);

      // Check if the response is not successful
      if (response.status !== 201) {
        throw new Error("Failed to upload invoice");
      }

      const invoiceId = response.data.customerInvoiceId;

      if (!row.fileObject) return;

      const fileInfo = {
        documentType: 1,
        creationDate: moment().format("YYYY-MM-DDThh:mm:ss.SSS") + "Z",
        createdBy: 1,
        fileName: row.fileObject.name,
        fileSize: row.fileObject.size,
        mimeType: row.fileObject.type,
      };

      const fileResponse = await axios.post("/api/v1/file", fileInfo);
      const fileId = fileResponse.data.fileId;

      const formData = createFormData(row.fileObject, fileId);
      await axios.post("/api/v1/azureblobstorage", formData);

      const fileIdInfo = { fileId };
      await axios.put(`/api/v1/customerInvoices/updateFileId/${invoiceId}`, fileIdInfo);

      setTableData([]);
    } catch (error) {
      console.log(error);
      let errorMessage = "Something went wrong";

      if (error?.response?.data?.includes("Cannot insert duplicate key row")) {
        errorMessage = "Invoice already in database";
      }

      console.log(error.message);
      throw new Error(JSON.stringify({ ...newInvoice, error: errorMessage }));
    }
  }

  const [submitCounter, setSubmitCounter] = useState(0);
  const [submitCount, setSubmitCount] = useState(0);
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleInvoiceUpload = async () => {
    // Upload invoices to DB

    let submitCount2 = tableData.length;

    setSubmitCount(tableData.length);
    setSubmitCounter(0);
    setIsSubmitting(true);
    setErrorMessages([]); // Reset error messages

    let successfulUploads = 0; // Variable to track successful uploads
    const errorMessages = []; // Array to store error messages

    for (const row of tableData) {
      if (submitCounter !== submitCount2) {
        setSubmitCounter((prevCounter) => prevCounter + 1);
      }

      try {
        await uploadInvoiceToDB(row);
        successfulUploads++; // Increment successful uploads count
      } catch (error) {
        errorMessages.push(error.message); // Add the error message to the array
      }

      // await uploadInvoiceToDB(row);
      await delay(100);
    }

    // Check if all invoices were processed successfully
    if (successfulUploads === tableData.length) {
      setIsSubmitting(false);
      setTableData([]); // Empty the tableData state by setting it to an empty array
    }

    setErrorMessages(errorMessages); // Update the error messages state
    setIsSubmitting(false);
    setTableData([]); // Empty the tableData state by setting it to an empty array

    if (errorMessages.length > 0) {
      // If there are any errors, stop uploading invoices
      toggleErrorPopup(); // Open the error popup
    }
  };

  const acceptableFileExtentions = ["xlsx", "xls"];

  const checkFileExtention = (name) => {
    return acceptableFileExtentions.includes(name.split(".").pop().toLowerCase());
  };

  const readDataFromExcel = (fileData) => {
    const wb = XLSX.read(fileData, {
      type: "binary",
      cellDates: true,
      cellNF: false,
      cellText: false,
    });
    const jsonData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    setTableData(jsonData);
    // console.log(wb);
    // console.table(jsonData);
  };

  const handleFile = async (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;
    if (!checkFileExtention(uploadedFile.name)) {
      alert("Invalid file type!");
    }

    const fileData = await uploadedFile.arrayBuffer();

    readDataFromExcel(fileData);
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files

    if (acceptedFiles?.length) {
      const fileData = await acceptedFiles[0].arrayBuffer();
      readDataFromExcel(fileData);
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <React.Fragment>
      <Helmet title="Customers" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add Invoices
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/">
              Pages
            </Link>
            <Typography>Add Invoices</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Card mb={6}>
        <CardContent
          pb={1}
          sx={{
            marginBottom: 5,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Add Invoices
          </Typography>
          <Typography variant="body2" gutterBottom>
            In this table you can add invoices in the system. Select the customer and supplier first.
          </Typography>
        </CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box
              display="flex"
              {...getRootProps()}
              width="100%"
              height={100}
              sx={{
                alignContent: "center",
                alignItems: "center",
                border: 1,
                borderRadius: 1,
                borderColor: "rgba(224, 224, 224, 1)",
              }}
            >
              <input
                {...getInputProps()}
                type="file"
                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                multiple={false}
                onChange={(e) => handleFile(e)}
              />
              {isDragActive ? (
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Drop the files here ...
                </p>
              ) : (
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Drag 'n' drop tab separeted customer invoices CSV or TXT file here, or click to select
                </p>
              )}
            </Box>
            {isSubmitting ? (
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ minWidth: "100%", minHeight: 742 }}>
                {errorMessages.length > 0 ? (
                  <div>
                    <Typography color="error">Errors occurred during invoice upload:</Typography>
                    <ul>
                      {errorMessages.map((errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <React.Fragment>
                    <CircularProgress />
                    <Typography>
                      Processing invoice {submitCounter} from {submitCount}
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            ) : (
              <DataGrid
                idProperty="id"
                editMode="cell"
                getRowId={(row) => row.__rowNum__}
                disableColumnFilter
                components={{ Toolbar: GridToolbar }}
                density="compact"
                componentsProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: false },
                    printOptions: { disableToolbarButton: false },
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 250 },
                  },
                }}
                rowIndexColumn
                experimentalFeatures={{ newEditingApi: true }}
                rows={tableData}
                columns={columns}
                onCellEditStop={onChangeFileDrop1}
                pageSize={15}
                sx={{
                  height: 667,
                  width: "100%",
                  borderRadius: 1,
                  backgroundColor: "background.paper",
                  boxShadow: 2,
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                  marginTop: 10,
                }}
              />
            )}
          </Grid>
        </Grid>
      </Card>
      <Button variant="outlined" onClick={handleInvoiceUpload} disabled={isSubmitting}>
        Add invoices to Database
      </Button>

      {/* Error popup */}
      <ErrorPopup
        errorMessages={errorMessages}
        onClose={closeErrorPopup} // Use the closeErrorPopup function to close the popup
        open={isErrorPopupOpen} // Pass the isErrorPopupOpen state to control the popup visibility
      />
    </React.Fragment>
  );
};

export default AddInvoices;
