import React, { useState, useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import {
  Box,
  Button,
  IconButton,
  Paper,
  LinearProgress,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment";
// import { GlobalContext } from "../../contexts/GlobalContext";
import PlusCircleOutline from "@mui/icons-material/ZoomIn";
import MinusCircleOutline from "@mui/icons-material/ZoomOut";
import DataGridExport from "../../components/DataGridExport"; // Adjust the import path as necessary
import apiUtils from "../../utils/apiUtils";

const AddAutomatedInvoicesComponent = () => {
  // const { customerId } = useContext(GlobalContext);
  const [activeTab, setActiveTab] = useState(0); // 0 for 'Upload Invoices' tab
  const [files, setFiles] = useState([]);
  const [uploadResults, setUploadResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [allUploaded, setAllUploaded] = useState(false);
  const [buttonText, setButtonText] = useState("Upload");
  const dataGridRef = useRef(null);
  const [uploadHistory, setUploadHistory] = useState([]);

  // State to manage expanded uploaders and batches
  const [expandedUploaders, setExpandedUploaders] = useState([]); // Array of uploadedBy IDs
  const [expandedBatches, setExpandedBatches] = useState({}); // { uploadedBy: [batchId1, batchId2, ...] }

  const [fileCount, setFileCount] = useState(0); // New state for file count

  const rowHeight = 39; // Set a consistent row height for all grids

  const dwsUserData = apiUtils.useApiData("/api/v1/GetDWSUsers");

  const successfulUploads = uploadResults.filter((result) => result.status === "Uploaded successfully").length;

  // Calculate the total number of uploads
  const totalUploads = uploadResults.length;

  // useEffect(() => {
  //   // Debugging the uploadHistory data
  //   // console.log("Upload History:", uploadHistory);
  // }, [uploadHistory]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 1) {
      fetchUploadHistory();
    }
  };

  const fetchUploadHistory = async () => {
    try {
      const response = await axios.get(`/api/v1/OCRFile`);
      setUploadHistory(response.data);
    } catch (error) {
      console.error("Error fetching upload history:", error);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      file,
      id: Date.now() + Math.random(),
      uploading: false,
      uploaded: false,
      progress: 0,
      size: file.size,
      type: file.type,
      error: file.size > 5500000 ? "File is too large, max allowed file size is 5.5MB" : null,
    }));
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...newFiles];
      const validFileCount = updatedFiles.filter((file) => !file.error).length;
      setFileCount(validFileCount);
      return updatedFiles;
    });
    setButtonText("Upload");
    setAllUploaded(false);
  }, []);

  const handleRemoveFile = (id) => {
    if (!uploading) {
      setFiles((prevFiles) => {
        const updatedFiles = prevFiles.filter((file) => file.id !== id);
        const validFileCount = updatedFiles.filter((file) => !file.error).length;
        setFileCount(validFileCount);
        return updatedFiles;
      });
    }
  };

  const scrollContainerRef = useRef(null);

  const handleUpload = async () => {
    if (buttonText === "Show Results") {
      setOpen(true);
      return;
    }

    setUploading(true);
    const results = [];

    try {
      // 1. Create a new Batch
      const batchInfo = {
        uploadedBy: 1, // Replace with actual user ID if available
        // Add any additional batch metadata if required
      };

      const batchResponse = await axios.post("/api/v1/OCRBatch", batchInfo);
      const batchId = batchResponse.data.batchId;

      console.log("Created Batch ID:", batchId);

      // 2. Prepare file creation and upload promises
      const uploadPromises = files.map(async (fileObj) => {
        if (!fileObj.uploaded && !fileObj.error && fileObj.size <= 5500000) {
          // a. Create file entry in OCRUploadedFiles
          const fileInfo = {
            documentType: 1,
            processedByDWS: 1, // Replace with actual processing info
            deleted: 0, // Default value
            fileName: fileObj.file.name, // Original filename; will use BatchId as prefix during upload
            mimeType: fileObj.type,
            fileSize: fileObj.size,
            batchId: batchId,
            uploadDate: moment().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
            uploadedBy: "", // Replace with actual user ID if available
          };

          try {
            const fileResponse = await axios.post("/api/v1/OCRFile", fileInfo);
            const fileId = fileResponse.data.fileId;

            // b. Upload file to Azure Blob with BatchId as part of the filename
            const formData = new FormData();
            const uniqueFileName = fileId;
            formData.append("file", fileObj.file, uniqueFileName + ".pdf");

            // console.log("Uploading file:", uniqueFileName);

            const uploadResponse = await axios.post("/api/v1/azureocrblobstorage", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setFiles((prevFiles) => prevFiles.map((f) => (f.id === fileObj.id ? { ...f, progress, uploading: true } : f)));

                // Scroll to the file being uploaded
                requestAnimationFrame(() => scrollToFile(fileObj.id, files));
              },
            });

            if (uploadResponse.status === 200) {
              // c. Update file status as uploaded
              setFiles((prevFiles) =>
                prevFiles.map((f) =>
                  f.id === fileObj.id ? { ...f, progress: 100, uploaded: true, uploading: false, error: null } : f
                )
              );
              results.push({ id: fileObj.id, file: fileObj.file.name, status: "Uploaded successfully" });
            }
          } catch (error) {
            console.error("Upload failed for file:", fileObj.file.name, error);
            setFiles((prevFiles) =>
              prevFiles.map((f) =>
                f.id === fileObj.id ? { ...f, uploading: false, error: error.response?.data || error.message } : f
              )
            );
            results.push({
              id: fileObj.id,
              file: fileObj.file.name,
              status: `Error: ${error.response?.data || error.message}`,
            });

            // Scroll one file further in case of error
            requestAnimationFrame(() => scrollToFile(fileObj.id, files, true));
          }
        } else if (fileObj.error) {
          results.push({ id: fileObj.id, file: fileObj.file.name, status: `Error: ${fileObj.error}` });

          // Scroll one file further in case of error
          requestAnimationFrame(() => scrollToFile(fileObj.id, files, true));
        }
      });

      // 3. Execute all upload promises
      await Promise.all(uploadPromises);

      setUploadResults(results);
      setOpen(true);
      setUploading(false);
      setButtonText("Show Results");
      setAllUploaded(true); // Set allUploaded to true after upload is done

      // Ensure the grid is scrolled completely to the bottom when upload is done
      if (scrollContainerRef.current) {
        console.log(scrollContainerRef.current);
        const gridElement = scrollContainerRef.current;
        gridElement.scrollTop = gridElement.scrollHeight;
        console.log(gridElement.scrollTop);
      }
    } catch (batchError) {
      console.error("Batch creation failed:", batchError);
      // Handle batch creation error
      setUploading(false);
      setButtonText("Show Results");
      setAllUploaded(false);
      setUploadResults([{ id: null, file: null, status: `Batch Error: ${batchError.response?.data || batchError.message}` }]);
      setOpen(true);
    }
  };

  const scrollToFile = (fileId, files, skipError = false) => {
    const rowIndex = files.findIndex((f) => f.id === fileId);
    if (rowIndex !== -1 && scrollContainerRef.current) {
      const gridElement = scrollContainerRef.current;
      const visibleHeight = gridElement.clientHeight;
      let newScrollTop = rowIndex * rowHeight - visibleHeight / 2 + rowHeight / 2;

      if (skipError) {
        newScrollTop += rowHeight;
      }

      requestAnimationFrame(() => {
        gridElement.scrollTop = newScrollTop;
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Handlers for expanding/collapsing uploaders and batches
  const handleToggleUploader = (uploadedBy) => {
    setExpandedUploaders((prev) => (prev.includes(uploadedBy) ? prev.filter((id) => id !== uploadedBy) : [...prev, uploadedBy]));
  };

  const handleToggleBatch = (uploadedBy, batchId) => {
    setExpandedBatches((prev) => {
      const userBatches = prev[uploadedBy] || [];
      return {
        ...prev,
        [uploadedBy]: userBatches.includes(batchId) ? userBatches.filter((id) => id !== batchId) : [...userBatches, batchId],
      };
    });
  };

  // Group uploadHistory by uploadedBy and then by batchId
  const groupedHistory = uploadHistory.reduce((acc, file) => {
    const uploadedBy = dwsUserData.find((u) => u.id === file.uploadedBy).firstname;

    if (!acc[uploadedBy]) {
      acc[uploadedBy] = { batches: {}, totalSize: 0 };
    }
    const batchId = file.batchId;
    if (!acc[uploadedBy].batches[batchId]) {
      acc[uploadedBy].batches[batchId] = { files: [], totalSize: 0, uploadDate: file.uploadDate };
    }
    const fileSize = parseInt(file.fileSize, 10); // Ensure fileSize is an integer
    if (!isNaN(fileSize)) {
      acc[uploadedBy].batches[batchId].files.push({ ...file, id: file.fileId, fileSize });
      acc[uploadedBy].batches[batchId].totalSize += fileSize;
      acc[uploadedBy].totalSize += fileSize;
    } else {
      console.error(`Invalid fileSize for file: ${file.fileName}`, file.fileSize);
    }
    return acc;
  }, {});

  // Convert groupedHistory to rows for uploaders
  const uploaderRows = Object.keys(groupedHistory).map((uploadedBy, index) => ({
    id: `uploader-${uploadedBy}-${index}`, // Ensure unique id
    uploadedBy,
    batchCount: Object.keys(groupedHistory[uploadedBy].batches).length,
    totalSize: (groupedHistory[uploadedBy].totalSize / 1024).toFixed(2) + " KB", // Convert size to KB
  }));

  // Function to convert batch data to rows
  const getBatchRows = (uploadedBy) => {
    const batches = groupedHistory[uploadedBy].batches;
    return Object.keys(batches).map((batchId, index) => {
      const hasAnyMissingDocument = batches[batchId].files.some((file) => !file.hasMatchingDocument);
      return {
        id: `batch-${uploadedBy}-${batchId}-${index}`, // Ensure unique id
        batchId,
        fileCount: batches[batchId].files.length,
        totalSize: (batches[batchId].totalSize / 1024).toFixed(2) + " KB", // Convert size to KB
        uploadDate: moment(batches[batchId].uploadDate).format("YYYY-MM-DD HH:mm:ss"),
        hasAnyMissingDocument, // New flag indicating if any file in the batch is missing a matching document
      };
    });
  };

  // Function to convert file data to rows
  const getFileRows = (uploadedBy, batchId) => {
    const files = groupedHistory[uploadedBy].batches[batchId].files;
    return files.map((file, index) => ({
      id: `file-${uploadedBy}-${batchId}-${file.fileId}-${index}`, // Ensure unique id
      fileName: file.fileName,
      mimeType: file.mimeType,
      fileSize: (file.fileSize / 1024).toFixed(2) + " KB", // Convert size to KB
      creationDate: moment(file.creationDate).format("YYYY-MM-DD HH:mm:ss"),
      hasMatchingDocument: file.hasMatchingDocument, // Assume this is the property name
    }));
  };

  const iconStyle = { height: "24px", width: "24px" }; // Adjusted to fit within 30px row height
  const iconButtonStyle = {
    height: "30px",
    width: "30px",
    padding: "0",
    margin: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const rowStyle = { height: "30px", borderBottom: "1px solid #ccc", padding: "4px 0 4px 12px", boxSizing: "border-box" };
  const detailedRowStyle = { height: "30px", borderBottom: "1px solid #ccc", padding: "8px 0 8px 12px", boxSizing: "border-box" };
  const thStyle = {
    height: "30px",
    borderBottom: "1px solid #ccc",
    padding: "4px 0 4px 8px",
    textAlign: "left",
    boxSizing: "border-box",
  };

  // Columns for uploader level
  const uploaderColumns = [
    { field: "uploadedBy", headerName: "Uploaded By", flex: 2 },
    { field: "batchCount", headerName: "Batch Count", flex: 1 },
    { field: "totalSize", headerName: "Total Size", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleToggleUploader(params.row.uploadedBy)} style={iconButtonStyle}>
          {expandedUploaders.includes(params.row.uploadedBy) ? <MinusCircleOutline /> : <PlusCircleOutline />}
        </IconButton>
      ),
    },
  ];

  // Columns for batch level
  const batchColumns = [
    { field: "batchId", headerName: "Batch ID", flex: 1.5 },
    { field: "uploadDate", headerName: "Upload Date", flex: 2 },
    { field: "fileCount", headerName: "File Count", flex: 1 },
    { field: "totalSize", headerName: "Total Size", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => (
        <IconButton onClick={() => handleToggleBatch(params.row.uploadedBy, params.row.batchId)} style={iconButtonStyle}>
          {expandedBatches[params.row.uploadedBy]?.includes(params.row.batchId) ? <MinusCircleOutline /> : <PlusCircleOutline />}
        </IconButton>
      ),
    },
  ];

  // Columns for file level
  const fileColumns = [
    { field: "fileName", headerName: "File Name", flex: 1.5 },
    { field: "mimeType", headerName: "MIME Type", flex: 1 },
    { field: "fileSize", headerName: "File Size", flex: 1 },
    { field: "creationDate", headerName: "Creation Date", flex: 1.5 },
  ];

  // Columns for uploaders table
  const renderUploaderTable = (columns, rows) => (
    <table style={{ width: "100%", borderCollapse: "collapse" }}>
      <thead>
        <tr style={rowStyle}>
          {columns.map((column) => (
            <th key={column.field} style={thStyle}>
              {column.headerName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <React.Fragment key={row.id}>
            <tr style={rowStyle}>
              {uploaderColumns.map((column) => (
                <td key={column.field} style={rowStyle}>
                  {column.renderCell ? column.renderCell({ row }) : row[column.field]}
                </td>
              ))}
            </tr>
            {expandedUploaders.includes(row.uploadedBy) && (
              <tr>
                <td colSpan={uploaderColumns.length} style={{ padding: 0, margin: 0 }}>
                  <div style={{ padding: "0 0 0 40px", margin: "0" }}>{renderBatchTable(row.uploadedBy)}</div>
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );

  // Function to render batches for a specific uploader
  const renderBatchTable = (uploadedBy) => {
    const batchRows = getBatchRows(uploadedBy).map((batchRow) => ({
      ...batchRow,
      uploadedBy, // Pass uploadedBy to handleToggleBatch
    }));

    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={rowStyle}>
            {batchColumns.map((column) => (
              <th key={column.field} style={thStyle}>
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {batchRows.map((batch) => (
            <React.Fragment key={batch.id}>
              <tr
                style={{
                  ...rowStyle,
                  backgroundColor: batch.hasAnyMissingDocument ? "#ffcccc" : "inherit", // soft red if any file lacks a matching document
                }}
              >
                {batchColumns.map((column) => (
                  <td key={column.field} style={rowStyle}>
                    {column.renderCell ? column.renderCell({ row: batch }) : batch[column.field]}
                  </td>
                ))}
              </tr>
              {expandedBatches[uploadedBy]?.includes(batch.batchId) && (
                <tr>
                  <td colSpan={batchColumns.length} style={{ padding: 0, margin: 0 }}>
                    <div style={{ padding: "0 0 0 40px", margin: "0" }}>{renderFileTable(uploadedBy, batch.batchId)}</div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  const renderFileTable = (uploadedBy, batchId) => {
    const fileRows = getFileRows(uploadedBy, batchId);
    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={rowStyle}>
            {fileColumns.map((column) => (
              <th key={column.field} style={thStyle}>
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fileRows.map((file) => (
            <tr
              key={file.id}
              style={{
                ...detailedRowStyle,
                backgroundColor: file.hasMatchingDocument ? "inherit" : "#ffcccc", // soft red
              }}
            >
              {fileColumns.map((column) => (
                <td key={column.field} style={detailedRowStyle}>
                  {file[column.field]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // Columns for upload results dialog
  const resultColumns = [
    { field: "file", headerName: "File Name", flex: 1.5 },
    { field: "status", headerName: "Status", flex: 4 },
  ];

  return (
    <React.Fragment>
      <Tabs value={activeTab} onChange={handleTabChange} aria-label="Invoice Tabs">
        <Tab label="Upload Invoices" sx={{ typography: "h6", fontWeight: "bold" }} />
        <Tab label="Upload History" sx={{ typography: "h6", fontWeight: "bold" }} />
      </Tabs>

      <Box sx={{ p: 2 }}>
        {activeTab === 0 ? (
          <React.Fragment>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box
                {...getRootProps()}
                sx={{
                  border: "2px dashed gray",
                  borderRadius: "4px",
                  p: 4,
                  textAlign: "center",
                  mb: 2,
                  cursor: "pointer",
                  flex: 1,
                }}
              >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </Box>
            </Box>
            <Paper sx={{ height: 635, mb: 2, overflow: "hidden", position: "relative" }} ref={dataGridRef}>
              <Box ref={scrollContainerRef} sx={{ height: "calc(100% - 50px)", overflow: "auto" }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr style={rowStyle}>
                      {[
                        { field: "fileName", headerName: "File Name", flex: 1.5 },
                        { field: "size", headerName: "File Size", flex: 1 },
                        { field: "type", headerName: "MIME Type", flex: 1 },
                        { field: "progress", headerName: "Upload Progress", flex: 2 },
                        { field: "actions", headerName: "Actions", flex: 1 },
                      ].map((column) => (
                        <th key={column.field} style={thStyle}>
                          {column.headerName}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((fileObj) => (
                      <tr key={fileObj.id} style={rowStyle}>
                        <td style={rowStyle}>{fileObj.file.name}</td>
                        <td style={rowStyle}>{(fileObj.size / 1024).toFixed(2)} KB</td>
                        <td style={rowStyle}>{fileObj.type}</td>
                        <td style={rowStyle}>
                          <LinearProgress variant="determinate" value={fileObj.progress} />
                        </td>
                        <td style={rowStyle}>
                          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", height: "30px" }}>
                            {fileObj.uploaded ? (
                              <CheckCircleIcon color="success" style={iconStyle} />
                            ) : fileObj.error ? (
                              <>
                                <Tooltip title={fileObj.error}>
                                  <ErrorIcon color="error" style={iconStyle} />
                                </Tooltip>
                                {!uploading && !fileObj.uploaded && !allUploaded && (
                                  <IconButton onClick={() => handleRemoveFile(fileObj.id)} style={iconButtonStyle}>
                                    <DeleteIcon style={iconStyle} />
                                  </IconButton>
                                )}
                              </>
                            ) : (
                              !fileObj.uploading &&
                              !fileObj.uploaded &&
                              !allUploaded && (
                                <IconButton onClick={() => handleRemoveFile(fileObj.id)} disabled={uploading} style={iconButtonStyle}>
                                  <DeleteIcon style={iconStyle} />
                                </IconButton>
                              )
                            )}
                          </Box>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>
              <Box
                sx={{
                  height: 50,
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 2,
                  borderTop: "1px solid #ccc",
                  position: "absolute",
                  bottom: 0,
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              >
                <p>Total invoices to be uploaded: {fileCount}</p>
              </Box>
            </Paper>

            <Button variant="contained" color="primary" onClick={handleUpload} disabled={uploading}>
              {buttonText}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Paper sx={{ height: 635, mb: 2, overflow: "auto" }}>{renderUploaderTable(uploaderColumns, uploaderRows)}</Paper>
          </React.Fragment>
        )}
      </Box>

      {/* Dialog for Upload Results */}
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle>Upload Results</DialogTitle>
        <DialogContent>
          {/* Summary of Upload Results */}
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6">{`${successfulUploads} out of ${totalUploads} invoices were successfully uploaded.`}</Typography>
          </Box>

          {/* Export Button */}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <DataGridExport tableData={uploadResults} columns={resultColumns} />
          </Box>

          {/* Upload Results Table */}
          <Box sx={{ height: 635, width: "100%" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr style={rowStyle}>
                  {resultColumns.map((column) => (
                    <th key={column.field} style={thStyle}>
                      {column.headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {uploadResults.map((result) => (
                  <tr key={result.id} style={rowStyle}>
                    <td style={rowStyle}>{result.file}</td>
                    <td style={rowStyle}>{result.status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default AddAutomatedInvoicesComponent;
