import React, { useEffect, useState, useMemo, useCallback } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Grid,
  Box,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Alert as MuiAlert,
  Typography,
} from "@mui/material";
import axios from "axios";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import SelectWrapper from "./SelectBox";
import { useDropzone } from "react-dropzone";
import moment from "moment";
import apiUtils from "../utils/apiUtils";
// import { use } from "i18next";

const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);
const TextField = styled(MuiTextField)(spacing);
const Button = styled(MuiButton)(spacing);

// Utility function to simulate timeout
// const timeOut = (time) => new Promise((res) => setTimeout(res, time));

// Validation schema factory
const createValidationSchema = (showExtraFields) => {
  let schema = Yup.object().shape({
    // customerId: Yup.string().required("Required"),
    customerNumber: Yup.string().required("Required"),
    invoiceNumber: Yup.string().required("Required"),
    invoiceDate: Yup.string().required("Required"),
    supplierId: Yup.string().required("Required"),
    countryCode: Yup.string().required("Required"),
    claimProductTypeCode: Yup.string().required("Required"),
    currencyId: Yup.string().required("Required"),
    invoiceGrossAmount: Yup.string()
      .required("Required")
      .matches(/^-?[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers, dots, and an optional negative sign are allowed."),
    invoiceVATAmountLocalCurrency: Yup.string()
      .required("Required")
      .matches(/^-?[0-9]+(\.[0-9]+)?$/, "Invalid format. Only numbers, dots, and an optional negative sign are allowed."),
    // claimId: Yup.string().required("Required"),
    // invoiceLabelId: Yup.string().required("Required"),
  });

  if (showExtraFields) {
    schema = schema.concat(
      Yup.object().shape({
        languageCode: Yup.string().required("Language is required"),
        freeText: Yup.string().required("Free text is required"),
      })
    );
  }

  return schema;
};

export default function ViewCustomerInvoiceUploadDialog(props) {
  const { viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog, refreshGrid, activeTab } = props;

  // State declarations
  const [isNewFile, setIsNewFile] = useState(false);
  const [isRemovedFile, setIsRemovedFile] = useState(false);
  // const [isUpdatedFile, setIsUpdatedFile] = useState(false);
  // const [fileContent, setFileContent] = useState(null);
  // const [editedData, setEditedData] = useState({});
  const [customerId, setCustomerId] = useState({});
  // const [currentCountryCode, setCurrentCountryCode] = useState(0);
  const [currentInvoiceState, setCurrentInvoiceState] = useState(viewCustomerInvoiceDialog?.params?.invoiceStateId ?? 3);

  const [showExtraFields, setShowExtraFields] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const productTypeData = apiUtils.useApiData("/api/v1/productTypes");
  // const [selectedCustomProductLanguage, setSelectedCustomProductLanguage] = useState("");

  // const [loading, setLoading] = useState(false); // Track loading state for the OCR function
  // const [isSubmitting, setIsSubmitting] = useState(false); // Track for general form submission

  const initialCustomProductLanguage = viewCustomerInvoiceDialog?.params?.productCodeDescriptionLanguageCountry;
  const initialProductCodeDescription = viewCustomerInvoiceDialog?.params?.productCodeDescription;

  // console.table(viewCustomerInvoiceDialog);

  // const [initialInvoiceGrossAmount, setInitialInvoiceGrossAmount] = useState(viewCustomerInvoiceDialog?.params?.invoiceGrossAmount);
  // const [initialInvoiceVATAmountLocalCurrency, setInitialInvoiceVATAmountLocalCurrency] = useState(
  //   viewCustomerInvoiceDialog?.params?.invoiceVATAmountLocalCurrency
  // );

  const [initialProductCode, setInitialProductCode] = useState(null);
  const [initialProductSubCode, setInitialProductSubCode] = useState(null);
  const initialProductTypeId = parseInt(viewCustomerInvoiceDialog?.params?.productId, 10);

  const [currentCountryCode, setCurrentCountryCode] = useState(
    props.countryArr.find((country) => country.countryCode === viewCustomerInvoiceDialog?.params?.countryCodeSupplier)?.countryId || ""
  );

  const [currentSupplierId, setCurrentSupplierId] = useState("");

  const extractVATNumber = (taxId, countryArr) => {
    if (!taxId) return "";
    const sortedCountries = [...countryArr].sort((a, b) => b.countryCode.length - a.countryCode.length);
    const matchedCountry = sortedCountries.find((country) => taxId.toUpperCase().startsWith(country.countryCode.toUpperCase()));
    return matchedCountry ? taxId.slice(matchedCountry.countryCode.length) : taxId;
  };

  useEffect(() => {
    const fullTaxIdSupplier = viewCustomerInvoiceDialog?.params?.taxIdSupplier || "";
    const extractedVATnr = extractVATNumber(fullTaxIdSupplier, props.countryArr);
    let initialSupplier = props.supplierArr.find((supplier) => supplier.supplierVATnr === extractedVATnr)?.supplierId || "";
    setCurrentSupplierId(initialSupplier);
  }, [viewCustomerInvoiceDialog, props]);

  // Set initial product codes based on productTypeData and initialProductTypeId
  useEffect(() => {
    // console.log("productTypeData:", productTypeData);
    // console.log("initialProductTypeId:", initialProductTypeId);

    if (productTypeData && productTypeData.length > 0 && initialProductTypeId) {
      const product = productTypeData.find((p) => p.productTypeId === initialProductTypeId);

      // console.log("Product found for given productTypeId:", product);

      if (product) {
        setInitialProductCode(product.categoryCode || "");
        // console.log("Product Code:", product.categoryCode);
        setInitialProductSubCode(product.subCode === "" ? "98" : product.subCode);
        // console.log("Product SubCode:", product.subCode);
      } else {
        console.warn("Product not found for given productTypeId");
      }
    } else {
      console.warn("Product type data or initial product type ID is missing.");
      setInitialProductCode("");
      setInitialProductSubCode("");
    }
  }, [productTypeData, initialProductTypeId]);

  // // Log the updated values once they are set
  // useEffect(() => {
  //   console.log("Initial Product Code after setting:", initialProductCode);
  //   console.log("Initial Product SubCode after setting:", initialProductSubCode);
  // }, [initialProductCode, initialProductSubCode]);

  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false);
  };

  // const handleRemoveFileClick = () => {
  //   setShowConfirmationDialog(true);
  // };

  useEffect(() => {
    setCustomerId(viewCustomerInvoiceDialog?.params?.entityId);
  }, [viewCustomerInvoiceDialog?.params?.entityId]);

  // useEffect(() => {
  //   const country = props.countryArr.find((country) => country.countryCode === viewCustomerInvoiceDialog?.params?.countryCodeSupplier);
  //   setCurrentCountryCode(country ? country.countryId : 0);
  // }, [viewCustomerInvoiceDialog?.params?.countryCodeSupplier, props.countryArr]);

  useEffect(() => {
    const initialCountry =
      props.countryArr.find((country) => country.countryCode === viewCustomerInvoiceDialog?.params?.countryCodeSupplier)?.countryId ||
      "";
    setCurrentCountryCode(initialCountry);
  }, [props.countryArr, viewCustomerInvoiceDialog?.params?.countryCodeSupplier]);

  useEffect(() => {
    setCurrentInvoiceState(viewCustomerInvoiceDialog?.params?.invoiceStateId ?? 3);
  }, [viewCustomerInvoiceDialog?.params?.invoiceStateId]);

  useEffect(() => {
    // Removed: setSelectedClaimDate
  }, [viewCustomerInvoiceDialog]);

  // useEffect(() => {
  //   if (viewCustomerInvoiceDialog.isOpen) {
  //     setCurrentInvoiceState(viewCustomerInvoiceDialog.params.invoiceStateId);
  //   }
  // }, [viewCustomerInvoiceDialog]);

  const [fileUrl, setFileUrl] = useState(null);

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      const selectedFile = acceptedFiles[0];
      const href = URL.createObjectURL(selectedFile);
      setFileUrl(href);
      if (isRemovedFile) {
        if (!isNewFile) {
          // setIsUpdatedFile(true);
        }
      } else {
        setIsNewFile(true);
      }
      // setFileContent(selectedFile);
      setIsRemovedFile(false);
      refreshGrid();
    },
    [isRemovedFile, isNewFile, refreshGrid]
  );

  const downloadFile = useCallback(
    async (fileId, modelUsed) => {
      setFileUrl(""); // Reset file URL initially
      if (fileId !== "") {
        try {
          let hardError = 0;
          if (activeTab === 3) {
            hardError = 1;
          }
          const response = await axios({
            url: `/api/v1/azureocrblobstorage/${fileId}/${modelUsed}/${hardError}`,
            method: "GET",
            responseType: "blob",
          });
          const href = URL.createObjectURL(response.data);
          setFileUrl(href);
        } catch (error) {
          setFileUrl("NoFile");
          console.error("Error downloading file:", error);
        }
      } else {
        setFileUrl("NoFile");
      }
    },
    [activeTab]
  );

  // Updated useEffect with downloadFile as a dependency
  useEffect(() => {
    if (viewCustomerInvoiceDialog?.downloadFile) {
      const fileId = viewCustomerInvoiceDialog?.params?.newFileName;
      const modelUsed = viewCustomerInvoiceDialog?.params?.modelUsed;

      if (fileId === "" || fileId === null) {
        setFileUrl("NoFile");
      } else {
        downloadFile(fileId, modelUsed);
      }

      setViewCustomerInvoiceDialog((prevState) => ({
        ...prevState,
        downloadFile: false,
      }));
    }
  }, [viewCustomerInvoiceDialog, setViewCustomerInvoiceDialog, downloadFile]);

  let errorMessage = "";
  let submitInError = false;

  const [groupedProductCodes, setGroupedProductCodes] = useState([]);
  const [groupedProductSubCodes, setGroupedProductSubCodes] = useState([]);

  // Group Product Codes based on Country
  useEffect(() => {
    const groupedProductTypes = productTypeData
      .filter((item) => item.countryCode === currentCountryCode)
      .reduce((acc, item) => {
        const key = item.categoryCode;
        acc[key] = acc[key] || [];
        acc[key].push(item.categoryCode + ". " + item.productShortDescription);
        return acc;
      }, {});

    const groupedOptions = Object.entries(groupedProductTypes).map(([key, values]) => ({
      key: key,
      value: values[0],
    }));

    setGroupedProductCodes(groupedOptions);
  }, [productTypeData, currentCountryCode]);

  // Group Product SubCodes based on Product Type and Country
  useEffect(() => {
    if (!initialProductCode) {
      setGroupedProductSubCodes([]);
      // setInitialProductSubCode("2");
      return;
    }

    // Find all sub codes related to the selected product type and country
    const relatedSubCodes = productTypeData.filter(
      (item) => item.categoryCode === initialProductCode && item.countryCode === currentCountryCode
    );

    const groupedSubCodeOptionsArray = relatedSubCodes
      .map((item) => ({
        key: item.subCode || "98", // Default to "98" if subCode is empty
        value: item.subCode ? `${item.subCode}. ${item.productLongDescription}` : "98. N/A",
      }))
      .sort((a, b) => {
        const numA = parseFloat(a.key);
        const numB = parseFloat(b.key);

        return !isNaN(numA) && !isNaN(numB) ? numA - numB : a.key.localeCompare(b.key);
      });

    // If Product Type is "10", add "99. Custom Value"
    if (initialProductCode === "10") {
      groupedSubCodeOptionsArray.push({ key: "99", value: "99. Custom Value" });
    }

    // Ensure there's at least one option
    if (groupedSubCodeOptionsArray.length === 0) {
      // No related subcodes found, set to empty array
      setGroupedProductSubCodes([]);
      // setInitialProductSubCode("1");
      return;
    }

    setGroupedProductSubCodes(groupedSubCodeOptionsArray);

    // Set the initial sub code only if initialProductCode is present
    // const defaultSubCode =
    //   initialProductSubCode || (groupedSubCodeOptionsArray.length === 1 ? groupedSubCodeOptionsArray[0].key : "981");
    // setInitialProductSubCode(defaultSubCode);
  }, [initialProductCode, productTypeData, currentCountryCode, initialProductSubCode]);

  // Show extra fields based on Product Code Description
  useEffect(() => {
    const productCodeDescription = viewCustomerInvoiceDialog?.params?.productCodeDescription;
    const productCodeDescriptionLanguageCountry = viewCustomerInvoiceDialog?.params?.productCodeDescriptionLanguageCountry;

    if (productCodeDescription && productCodeDescriptionLanguageCountry) {
      setShowExtraFields(true);
      // Automatically set Product Type to "10" and SubCode to "99" when extra fields are shown
      // This will be handled in Formik's initialValues and synchronization
    } else {
      setShowExtraFields(false);
    }
  }, [viewCustomerInvoiceDialog]);

  // Handlers for field changes
  const handleProductSubCodeChange = (e, setFieldValue) => {
    const value = e.target.value;
    setFieldValue("claimProductSubCodeId", value);
    setShowExtraFields(false);
    if (value === "99" || value === 99) {
      setShowExtraFields(true);
    }
  };

  const handleCustomProductLanguageChange = (e, setFieldValue) => {
    // setSelectedCustomProductLanguage(e.target.value);
    setFieldValue("languageCode", e.target.value);
  };

  const customSelectableLanguages = [
    { key: "en", value: "English" },
    { key: "ro", value: "Romanian" },
    { key: "cz", value: "Czech" },
    { key: "sk", value: "Slovak" },
  ];

  // Initial form values
  const initialValues = {
    customerId: customerId,
    invoiceNumber: viewCustomerInvoiceDialog?.params?.invoiceNumber || "",
    customerNumber: viewCustomerInvoiceDialog?.params?.customerNumber || "",
    invoiceDate: viewCustomerInvoiceDialog?.params?.invoiceDate
      ? moment(viewCustomerInvoiceDialog.params.invoiceDate).format("YYYY-MM-DD")
      : "",
    supplierId: currentSupplierId,
    countryCode: currentCountryCode,
    invoiceStateIdStatic: currentInvoiceState, // Adjusted to use actual state
    productTypeId: viewCustomerInvoiceDialog?.params?.productTypeId || "",
    claimProductTypeCode: initialProductCode || "",
    currencyId:
      props.countryArr.find((country) => country.countryCode === viewCustomerInvoiceDialog?.params?.countryCodeSupplier)?.currency ||
      "",
    invoiceGrossAmount: viewCustomerInvoiceDialog?.params?.totalExcludingTax || "",
    invoiceVATAmountLocalCurrency: viewCustomerInvoiceDialog?.params?.totalTax || "",
    invoiceVATAmountEUR: viewCustomerInvoiceDialog?.params?.invoiceVATAmountEUR || "",
    claimId: viewCustomerInvoiceDialog?.params?.claimId || "",
    invoiceLabelId: viewCustomerInvoiceDialog?.params?.invoiceLabelId || "",
    languageCode: initialCustomProductLanguage || "",
    freeText: viewCustomerInvoiceDialog?.params?.freeText
      ? viewCustomerInvoiceDialog?.params?.freeText
      : initialProductCodeDescription || "",
    claimProductSubCodeId: initialProductSubCode || "",
  };

  const validationSchema = createValidationSchema(showExtraFields);

  // Form submission handler
  const handleSubmit = async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
    // Logging form values for debugging
    console.log("Form submitted with values:", values);

    const matchedProduct = productTypeData.find(
      (item) =>
        item.categoryCode === values.claimProductTypeCode &&
        item.countryCode === values.countryCode &&
        item.subCode === (values.claimProductSubCodeId === "98" ? "" : values.claimProductSubCodeId)
    );

    let newCustomer = props.entitiesArr.find((entity) => entity.entityNumber === values.customerNumber);
    if (!newCustomer) {
      return setErrors({ customerNumber: "Customer not found" });
    }

    const updatedInvoice = {
      invoiceId: viewCustomerInvoiceDialog?.params?.invoiceId,
      invoiceNumber: values.invoiceNumber,
      customerNumber: values.customerNumber,
      invoiceDate: values.invoiceDate, // Use Formik's invoiceDate
      supplierId: values.supplierId,
      countryCode: values.countryCode,
      countryCodeCustomer: props.countryArr.find((country) => country.countryId === newCustomer.countryId).countryCode,
      countryCodeSupplier: props.countryArr.find((country) => country.countryId === values.countryCode).countryCode,
      taxIdCustomer: props.entitiesArr.find((entity) => entity.entityNumber === values.customerNumber).vat,
      taxIdSupplier: props.supplierArr.find((supplier) => supplier.supplierId === values.supplierId).supplierVATnr,
      currencyId: values.currencyId,
      totalExcludingTax: values.invoiceGrossAmount,
      totalTax: values.invoiceVATAmountLocalCurrency,
      claimProductSubCodeId: values.claimProductSubCodeId,
      claimProductTypeCode: values.claimProductTypeCode,
      productId: matchedProduct.productTypeId,
      invoiceStateId: currentInvoiceState,
      // claimProductTypeCode: values.claimProductTypeCode,
      // Add any other fields you want to update
    };
    console.log("Updated Invoice:", updatedInvoice);
    // Call the update function to update the DataGrid data
    props.updateInvoice(updatedInvoice);

    // Close the dialog after updating
    setViewCustomerInvoiceDialog({
      ...viewCustomerInvoiceDialog,
      isOpen: false,
    });

    // Optionally reset the form or handle other post-submit logic
    // resetForm();
  };

  // Dropzone base styles
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    minHeight: "300px",
    minWidth: "600px",
    marginTop: "200px",
    marginLeft: "200px",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  // Styled Dropzone Component
  function StyledDropzone(props) {
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      accept: { "application/pdf": [] },
      onDropAccepted,
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    return (
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />

          <p
            style={{
              display: "flex",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            No files are currently attached to this invoice. <br />
            Drag 'n' drop the invoice file here, or click to select
          </p>
        </div>
      </div>
    );
  }

  const handleRemoveFile = () => {
    setIsRemovedFile(true);
    setFileUrl("NoFile");
    refreshGrid();
    setShowConfirmationDialog(false);
  };

  const handleSelectedCountryChange = (event, setFieldValue) => {
    const countryId = event.target.value;
    setCurrentCountryCode(countryId); // Update de lokale state
    setFieldValue("countryCode", countryId);

    // Reset product type en subcode bij landwijziging
    setFieldValue("claimProductTypeCode", "");
    setFieldValue("claimProductSubCodeId", "");
  };

  const handleSelectedInvoiceStateChange = (event, setFieldValue) => {
    setCurrentInvoiceState(event.target.value);
    setFieldValue("invoiceStateIdStatic", event.target.value);
  };

  // const getOCRInvoiceData = async (setFieldValue) => {
  //   setLoading(true); // Start loading
  //   try {
  //     const formData = new FormData();
  //     formData.append("invoiceFile", fileContent); // Assuming `fileContent` is your file data

  //     const response = await axios.post("/api/v1/azureocranalyzeinvoice", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     const ocrData = response.data;
  //     // const grossAmount = ocrData.invoiceTotal?.amount || "";
  //     const netAmount = ocrData.subTotal?.amount || "";
  //     const vatAmount = ocrData.totalTax?.amount || "";
  //     const invoiceNumber = ocrData.invoiceId || "";
  //     const customerNumber = ocrData.customerNumber || "";
  //     const supplierTaxId = ocrData.vendorTaxId || "";

  //     // console.log("supplierTaxId:", supplierTaxId);

  //     // Find the supplier based on the extracted supplierTaxId (vendorTaxId)
  //     const matchingSupplier = props.supplierArr.find((supplier) => supplier.supplierVATnr === supplierTaxId);

  //     // If a matching supplier is found, set it in Formik
  //     if (matchingSupplier) {
  //       // console.log("supplierId:", matchingSupplier.supplierId);
  //       setFieldValue("supplierId", matchingSupplier.supplierId);
  //     }

  //     setFieldValue("invoiceGrossAmount", netAmount); // Set gross amount (including VAT)
  //     setFieldValue("invoiceVATAmountLocalCurrency", vatAmount); // Set VAT amount
  //     setFieldValue("invoiceNumber", invoiceNumber); // Set invoice number
  //     setFieldValue("customerNumber", customerNumber); // Set invoice number

  //     // console.log("OCR Response:", response.data);
  //   } catch (error) {
  //     console.error("Error uploading file:", error);
  //   } finally {
  //     setLoading(false); // Stop loading after completion
  //   }
  // };

  // console.table(props.invoiceStateArr);

  const handleSelectedProductCatagoryChange = (event, setFieldValue) => {
    // console.log("Product Type Code:", event.target.value);
    // console.log("Country Code:", currentCountryCode);
    // const productType = productTypeData.find(
    //   (item) => item.categoryCode === event.target.value && item.countryCode === currentCountryCode
    // );
    setInitialProductCode(event.target.value);
    // console.log("Product Type:", productType);
    // console.log("Product Type:", productType);
  };

  // Handler voor wijziging van de leverancier
  const handleSupplierChange = (event, setFieldValue) => {
    const selectedSupplierId = event.target.value;

    setCurrentSupplierId(selectedSupplierId);
    // Update het formulierveld 'supplierId' met de geselecteerde waarde
    setFieldValue("supplierId", selectedSupplierId);

    // Zoek de geselecteerde leverancier uit de supplierArr om het countryId te verkrijgen
    const selectedSupplier = props.supplierArr.find((supplier) => supplier.supplierId === selectedSupplierId);

    if (selectedSupplier) {
      // Update het formulierveld 'countryCode' op basis van de geselecteerde leverancier
      setCurrentCountryCode(selectedSupplier.countryCode);
      setFieldValue("countryCode", selectedSupplier.countryCode);

      // Optioneel: Reset andere gerelateerde velden bij wijziging van de leverancier
      setFieldValue("claimProductTypeCode", "");
      setFieldValue("claimProductSubCodeId", "");
    }
  };

  return (
    <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, status, setFieldValue }) => (
        <Card mb={6}>
          <CardContent>
            {submitInError ? (
              <Alert severity="error" my={3}>
                {errorMessage}
              </Alert>
            ) : errorMessage.length > 0 ? (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            ) : null}
            {activeTab === 3 ? <></> : null}
            {isSubmitting ? (
              <Box minHeight="700px" display="flex" justifyContent="center" my={20} mx={40}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  {activeTab !== 3 && (
                    <Grid item md={3} xs={12}>
                      <Grid item xs={12}>
                        <TextField
                          name="customerNumber"
                          label="Customer number"
                          value={values.customerNumber}
                          error={Boolean(touched.customerNumber && errors.customerNumber)}
                          fullWidth
                          helperText={touched.customerNumber && errors.customerNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!values.customerNumber, // Automatically shrink the label when there's a value
                          }}
                          my={2}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="invoiceNumber"
                          label="Invoice number"
                          value={values.invoiceNumber}
                          error={Boolean(touched.invoiceNumber && errors.invoiceNumber)}
                          fullWidth
                          helperText={touched.invoiceNumber && errors.invoiceNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!values.invoiceNumber, // Automatically shrink the label when there's a value
                          }}
                          my={2}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="invoiceDate"
                          name="invoiceDate"
                          label="Invoice date"
                          type="date"
                          value={values.invoiceDate}
                          onChange={handleChange}
                          fullWidth
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={Boolean(touched.invoiceDate && errors.invoiceDate)}
                          helperText={touched.invoiceDate && errors.invoiceDate ? errors.invoiceDate : ""}
                          variant="outlined"
                          my={2}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWrapper
                          name="invoiceStateIdStatic"
                          label="Invoice State"
                          value={currentInvoiceState}
                          onChange={(e) => handleSelectedInvoiceStateChange(e, setFieldValue)}
                          options={props.invoiceStateArr.map((item) => ({
                            key: item.invoiceStateId,
                            value: item.invoiceStateDescription,
                          }))}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <SelectWrapper
                          name="supplierId"
                          label="Leverancier"
                          value={currentSupplierId} // Gebruik de lokale state
                          options={props.supplierArr.map((supplier) => ({
                            key: supplier.supplierId,
                            value: supplier.supplierName,
                          }))}
                          onChange={(event) => handleSupplierChange(event, setFieldValue)}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWrapper
                          name="countryCode"
                          label="Country"
                          value={values.countryCode}
                          onChange={(e) => handleSelectedCountryChange(e, setFieldValue)}
                          options={props.countryArr.map((country) => ({
                            key: country.countryId,
                            value: country.countryCode,
                          }))}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWrapper
                          name="claimProductTypeCode"
                          label="Product Type"
                          value={values.claimProductTypeCode}
                          onChange={(e) => {
                            handleSelectedProductCatagoryChange(e, setFieldValue); // Let Formik handle the change
                            // Additional logic is handled in the SubCodeSynchronizer
                          }}
                          options={groupedProductCodes}
                          error={Boolean(touched.claimProductTypeCode && errors.claimProductTypeCode)}
                          fullWidth
                          helperText={touched.claimProductTypeCode && errors.claimProductTypeCode ? errors.claimProductTypeCode : ""}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectWrapper
                          name="claimProductSubCodeId"
                          label="Product SubCode"
                          value={values.claimProductSubCodeId}
                          options={groupedProductSubCodes}
                          onChange={(e) => handleProductSubCodeChange(e, setFieldValue)}
                        />
                      </Grid>
                      {showExtraFields && (
                        <>
                          <Grid item xs={12}>
                            <SelectWrapper
                              name="languageCode"
                              label="FreeText Language"
                              value={values.languageCode}
                              options={customSelectableLanguages}
                              onChange={(e) => handleCustomProductLanguageChange(e, setFieldValue)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="freeText"
                              label="FreeText"
                              value={values.freeText}
                              error={Boolean(touched.freeText && errors.freeText)}
                              fullWidth
                              helperText={touched.freeText && errors.freeText ? errors.freeText : ""}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              variant="outlined"
                              my={2}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={12}>
                        <SelectWrapper
                          name="currencyId"
                          label="Currency"
                          value={values.currencyId}
                          onChange={handleChange}
                          options={props.currencyArr.map((currency) => ({
                            key: currency.currencyId,
                            value: currency.isoCode,
                          }))}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="invoiceGrossAmount"
                          label="Invoice gross amount"
                          value={values.invoiceGrossAmount || ""} // Ensure value is set, default to empty string if null
                          error={Boolean(touched.invoiceGrossAmount && errors.invoiceGrossAmount)}
                          fullWidth
                          helperText={touched.invoiceGrossAmount && errors.invoiceGrossAmount ? errors.invoiceGrossAmount : ""}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number" // Use number input for amounts
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!values.invoiceGrossAmount, // Automatically shrink the label when there's a value
                          }}
                          my={2}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="invoiceVATAmountLocalCurrency"
                          label="Invoice VAT amount"
                          value={values.invoiceVATAmountLocalCurrency || ""} // Ensure value is set, default to empty string if null
                          error={Boolean(touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency)}
                          fullWidth
                          helperText={
                            touched.invoiceVATAmountLocalCurrency && errors.invoiceVATAmountLocalCurrency
                              ? errors.invoiceVATAmountLocalCurrency
                              : ""
                          }
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="number" // Use number input for amounts
                          variant="outlined"
                          InputLabelProps={{
                            shrink: !!values.invoiceVATAmountLocalCurrency, // Automatically shrink the label when there's a value
                          }}
                          my={2}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid item md={3} xs={12}>
                    <Grid item md={9} xs={12}>
                      {fileUrl === "" ? (
                        <div className="App">
                          <Typography
                            sx={{
                              fontSize: "21px",
                              mt: 2,
                              pt: "394px",
                              pb: "394px",
                              display: "flex",
                              justifyContent: "center",
                              border: 1,
                              borderColor: "lightgray",
                              width: "1000px",
                            }}
                          >
                            <CircularProgress size={30} sx={{ mr: 2 }} />
                            Loading file, please wait!
                          </Typography>
                        </div>
                      ) : fileUrl === "NoFile" || fileUrl === null ? (
                        <div className="App">
                          <StyledDropzone />
                        </div>
                      ) : (
                        <div className="App">
                          <div style={{ display: "flex", width: "1015px" }}>
                            <Dialog open={showConfirmationDialog} onClose={handleConfirmationDialogClose}>
                              <DialogTitle>Confirmation</DialogTitle>
                              <DialogContent>
                                <DialogContentText>Are you sure you want to delete the invoice copy?</DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleConfirmationDialogClose} color="primary">
                                  Cancel
                                </Button>
                                <Button onClick={handleRemoveFile} color="primary">
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                          <object
                            width={1000}
                            height={800}
                            data={fileUrl}
                            type="application/pdf"
                            style={{
                              marginTop: "8px",
                            }}
                          >
                            <iframe title="file" src={fileUrl + "&embedded=true"}></iframe>
                          </object>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {activeTab !== 3 && (
                  <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "20px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                      my={2}
                      mr={4}
                      disabled={isSubmitting} // Disable when loading
                    >
                      Update Invoice
                    </Button>

                    {/* <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    mt={3}
                    my={2}
                    disabled={loading || isSubmitting} // Disable when loading
                    onClick={() =>
                      setViewCustomerInvoiceDialog({
                        ...viewCustomerInvoiceDialog,
                        isOpen: false,
                      })
                    }
                  >
                    Close
                  </Button>

                  <Button
                    type="button"
                    variant="contained"
                    color="warning"
                    mt={3}
                    my={2}
                    onClick={() => getOCRInvoiceData(setFieldValue)} // Pass setFieldValue from Formik
                    disabled={loading || isSubmitting}
                    style={{ marginLeft: "auto" }} // This pushes the button to the right
                  >
                    {loading ? (
                      <>
                        <CircularProgress size={20} style={{ marginRight: 8 }} />
                        Getting OCR Data
                      </>
                    ) : (
                      "Get OCR Invoice Data"
                    )}
                  </Button> */}
                  </div>
                )}
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}
