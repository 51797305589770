// UploadStatusModal.js
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress, Typography, Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import DataGridExport from "./DataGridExport"; // Adjust path as needed

const UploadStatusModal = ({ isOpen, onClose, progress, results }) => {
  // Define columns for the DataGrid
  const columns = [
    { field: "customerNumber", headerName: "Customer Number", flex: 1 },
    { field: "invoiceNumber", headerName: "Invoice Number", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "error",
      headerName: "Error",
      flex: 2,
      renderCell: (params) => (params.value ? params.value : "N/A"),
    },
  ];

  // Calculate the number of successful uploads
  const successCount = results ? results.filter((result) => result.status === "Success").length : 0;
  const totalCount = results ? results.length : 0;
  const invoiceLabel = totalCount === 1 ? "invoice" : "invoices";
  const multipleInvoices = totalCount === 1 ? "is" : "are";

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Upload Status</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Uploading invoices...
        </Typography>
        <LinearProgress variant="determinate" value={progress} />
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: "20px" }}>
          {progress}% completed
        </Typography>

        {/* Only show summary and DataGrid after completion */}
        {progress === 100 && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {/* Summary Message */}
            <Typography variant="h6" gutterBottom>
              {`${successCount} out of ${totalCount} ${invoiceLabel} ${multipleInvoices} successfully uploaded!`}
            </Typography>

            {/* Export buttons above the DataGrid, aligned to the right */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mb: 1, // Margin below to separate from DataGrid
              }}
            >
              <DataGridExport tableData={results} columns={columns} />
            </Box>
            {/* DataGrid Component */}
            <DataGrid
              rows={results.map((result, index) => ({ id: index, ...result }))}
              columns={columns}
              autoHeight
              disableSelectionOnClick
              pageSize={5}
              rowsPerPageOptions={[5]}
              getRowClassName={(params) => (params.row.status === "Success" ? "row-success" : "row-failure")}
              sx={{
                "& .row-success": {
                  backgroundColor: "rgba(144, 238, 144, 0.3)", // Light green
                },
                "& .row-failure": {
                  backgroundColor: "rgba(255, 182, 193, 0.3)", // Light red
                },
              }}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={progress < 100}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadStatusModal;
